import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo/stalmark-logo.svg";
// import facebook from "../img/social/facebook.svg";
// import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Stalmark E.Steć R.Steć sp.j. logo"
            style={{ width: "14em", height: "10em" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns is-mobile">
              <div className="column">
                {/* <p className="bd-notification is-info">First row column</p> */}
                <div className="columns">
                  <div className="column">
                    <section>
                      <ul className="menu-list">
                        <li>
                          {/* <Link className="navbar-item" to="/realizacje">
                            Realizacje
                      </Link> */}
                        </li>
                        {/* <li>
                          <Link className="navbar-item" to="/blog">
                            Ostatnie Wpisy
                      </Link>
                        </li> */}
                        <li>
                          <Link className="navbar-item" to="/gatunki-stali">
                            Gatunki Stali
                      </Link>
                        </li>
                        <li>
                          <Link className="navbar-item" to="/kontakt">
                            Kontakt
                      </Link>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div className="column">
                    <h4>email</h4>
                    <p>
                      <a href="mailto:stalmarkspj@gmail.com">
                        stalmarkspj@gmail.com
                  </a>
                    </p>

                    <h4>Telefon</h4>
                    <p>
                      <a href="tel:+48 15 8428198">+48 15 842 81 98</a>
                    </p>
                    <p>
                      <a href="tel:+48 15 8135858">+48 15 813 58 58</a>
                    </p>

                    <h4>Tel./ Fax</h4>
                    <p>
                      <a href="tel:+48 15 8135678">+48 15 813 56 78</a>
                    </p>
                  </div>
                  <div className="column">
                    <h4>"Stalmark" E.Steć R.Steć sp.j.</h4>
                    <p>ul. Eugeniusza Kwiatkowskiego 1B,</p>
                    <p>37-450 Stalowa Wola, Poland</p>
                    <p>KRS: 0000117765</p>
                    <p>Regon: 830219167</p>
                    <p>NIP: 865-10-05-178</p>
                  </div>
                </div>
              </div>

              {/* <div class="column">
                <p class="bd-notification is-danger">Second column</p>
                <div class="columns is-mobile">
                  <div class="column is-quarter">
                    <p class="bd-notification is-danger"></p>
                  </div>
                  <div class="column is-quarter">
                    <p class="bd-notification is-danger">Auto</p>
                  </div>
                  <div class="column is-quarter">
                    <p class="bd-notification is-danger">Auto</p>
                  </div>
                  <div class="column is-quarter">
                    <p class="bd-notification is-danger">Auto</p>
                  </div>
                </div>
              </div> */}
            </div>

            {/* <div className="columns is-mobile">
              <div className="column">
                <p className="bd-notification is-info">
                  sec row column sdsd
                </p>
              </div>
            </div> */}


            <div className="columns is-mobile">
              <div className="column">
                {/* <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
              </div>
              <div className="column">
                {/* <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
              </div>
              <div className="column">
                {/* <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
              </div>
              <div className="column">
                {/* <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
              </div>
            </div>
          </div>

        </div>
      </footer>
    );
  }
};

export default Footer;
